import en from './en'
import cn from './cn'
import kr from './kr'
import ar from './ar'
import es from './es'
import fr from './fr'
import pt from './pt'
import it from './it'

export default {
    en,
    cn,
    kr,
    ar,
    es,
    fr,
    pt,
    it
}
