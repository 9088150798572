const ar = {
    aftersales:{
        "contactus":"اتصل بنا للحصول على الاستجابة السريعة",
        "workorder":"قائمة أوامر العمل",
        "createjob":"إنشاء وظيفة",
        "submittinworkorder":"عند إرسال أمر عمل، يرجى تضمين أكبر قدر ممكن من المعلومات التفصيلية، مثل لقطات الشاشة الخاصة بالأخطاء، ومعلومات الحساب، وما إلى ذلك، وهي مهمة جدًا بالنسبة لنا للتعامل مع المشكلات بسرعة.",
        "workordercontent":"محتوى أمر العمل",
        "workorderreply":"رد أمر العمل",
        "subscriptionaccount":"حساب الاشتراك",
        "problemtype":"نوع المشكلة",
        "tobeprocessed":"ليتم معالجتها",
        "reply":"رد",
        "processed":"تمت المعالجة، عرض الردود",
        "revoked":"تم إبطاله",
        "create":"يخلق",
        "workorderno":"أمر العمل رقم",
        "product":"منتج",
        "status":"حالة",
        "type":"يكتب",
        "ticket":"تذكرة",
        "createtime":"خلق الوقت",
    },
    excode:{
        "redemptioncode":"استرداد الرمز",
        "pleaseredemptioncode":"الرجاء إدخال رمز الاسترداد",
        "exchange":"يستخدم",
        "notused":"غير مستخدم",
        "used":"مستخدم",
        "expired":"منتهي الصلاحية",
        "status":"حالة",
        "exchangetime":"تاريخ الصرف",
        "remark":"ملاحظة",
        "createtime":"خلق الوقت",
    },
    footer:{
        "contactus":"اتصل بنا",
        "navigation":"ملاحة",
        "home":"بيت",
        "friends":"أصدقاء",
        "subscribe":"يشترك",
        "aftersales":"ما بعد البيع",
        "help":"يساعد",
        "termsofuse":"شروط الاستخدام",
        "refundpolicy":"سياسة استرداد الأموال",
        "privacypolicy":"سياسة الخصوصية",
        "paymentmethod":"طريقة الدفع",
        "language":"لغة",
    },
    friends:{
        "promotioninfo":"معلومات الترويج",
        "currentlevel":"المستوى الحالي",
        "content":"يمكنك استخدام رابط الدعوة الحصري وأكواد الخصم لمشاركتها مع الآخرين لكسب العمولات. كلما ارتفع المستوى، زادت حصة العمولة",
        "rolemembers":"دور الأعضاء",
        "promotionallinks":"رابط الدعوة",
        "discountcodes":"رموز الخصم",
        "copy":"ينسخ",
        "rebateratio":"معدل العمولة",
        "discountratio":"نسبة الخصم",
        "lowestdiscountratio":"خصم اشتراك المستخدم",
        "walletsandpoints":"المحافظ وتجميد المبلغ",
        "applywithdrawal":"بعد تقديم طلب السحب، سنقوم بمراجعة المبلغ وسداده خلال 3 أيام عمل",
        "withdraw":"ينسحب",
        "record":"سِجِلّ",
        "integral":"أساسي",
        "wallet":"محافظ",
        "freezeamount":"تجميد",
        "pointsused":"تم تجميد منتجات YouTube لمدة 3 أيام، وتم تجميد المنتجات الأخرى لمدة يوم واحد",
        "orderdetails":"تفاصيل الطلب",
        "orderamount":"مبلغ الطلب",
        "earnings":"الأرباح",
        "withdrawalsrecord":"سجل عمليات السحب",
        "accounts":"الحسابات",
        "accounttype":"نوع الحساب",
        "withdrawalamount":"كمية",
        "cancel":"يلغي",
        "submit":"يُقدِّم",

        "greaterthan":"يجب أن يكون المبلغ أكبر من 0!",
        "normal":"طبيعي",
        "invalid":"غير صالح",
        "freeze":"تجميد",
        "withdrawing":"الانسحاب",
        "extracted":"مستخرج",

        "bankcard":"البطاقة المصرفية",
        "promotionlink":"رابط الترويج",
        "discountcode":"رمز الخصم",
        "waitingforapproval":"في انتظار الموافقة",
        "waitingforpayment":"في انتظار الدفع",
        "notpass":"لا تمر",
        "paid":"مدفوع",

        "cashwithdrawalid":"معرف السحب النقدي",
        "accountsreceivable":"حسابات القبض",
        "amount":"المبلغ (بالدولار الأمريكي)",
        "status":"حالة",
        "applicationtime":"وقت التطبيق",
        "processingresults":"نتائج المعالجة",

        "orderid":"معرف الطلب",
        "useramount":"مبلغ المستخدم",
        "duration":"مدة",
        "promotiontype":"نوع الترويج",
        "createtime":"خلق الوقت",
        "eainings":"الأرباح (بالدولار الأمريكي)",

        "receivingaccount":"حساب الاستلام",
        "nowithdrawableamount":"الحد الأدنى للسحب 30 دولارًا أمريكيًا",
        "copysuccessfully":"تم النسخ بنجاح",
        "copyfailed":"فشل النسخ",
        "activetime":"وقت النشاط",
    },
    home:{
        'join':'ينضم',
        "texttitle":"اشتراك مميز مشترك بسعر أقل",
        "buynow":"اشتري الآن",
        "soldout":"نقص المخزون",
        "shoptitle":"بعد تقديم الطلب، تحقق من رسالة البريد الإلكتروني الخاصة بالبريد الإلكتروني الحالي لتسجيل الدخول (يرجى التأكد من صحة عنوان البريد الإلكتروني)",
        "delivery":"التسليم في الوقت الحقيقي",
        "after":"التسليم في الوقت الحقيقي بعد الدفع دون انتظار",
        "certificate":"شهادة SSL",
        "payments":"تتم عمليات الدفع في بيئة آمنة باستخدام شهادة أمان SSL",
        "onlinesupport":"دعم مباشر على مدار 24 ساعة طوال أيام الأسبوع",
        "privatecustomer":"توفر Nfxbus خدمة عملاء خاصة عبر الإنترنت على مدار الساعة طوال أيام الأسبوع",
        "subscriber":"مشترك مشارك مناسب",
        "subscriptions":"مشاركة الاشتراك المتميز بسعر أقل. مطابقة الأولوية للمشتركين المشاركين من نفس البلد",
        "moneyback":"ضمان استرداد الأموال",
        "refund":"لديك 1-3 أيام لطلب استرداد الأموال (يوتيوب 3 أيام للمنتجات الأخرى 24 ساعة)",
        "pwdreset":"إعادة تعيين سريع للأشخاص ذوي الإعاقة",
        "pwdresetdesc":"الأشخاص ذوي الإعاقة لا يعمل؟ انقر فوق إعادة تعيين pwd في صفحة الاشتراك دون الانتظار والتشغيل اليدوي",
        "parkingspace":"مكان لوقوف السيارات",
        "gift":"هدية",
        "screen":"شاشة",
        "1screen":"ملف شخصي واحد (مشترك)",
        "maxnum":"الملفات الشخصية (خاص)",
        "month":"شهر",
        "buytyp":"نوع الشراء",
        "subscription":"الاشتراك",
        "voucher":"قسيمة (تجديد الشراء)",
        "discountcode":"رمز الخصم",
        "use":"يستخدم",
        "paymentmethod":"طريقة الدفع",
        "pleasechoose":"يرجى الاختيار",
        "billingdetails":"تفاصيل الفواتير",
        "payaddress":"معلومات عنوان الدفع",
        "firstname":"الاسم الأول",
        "lastname":"اسم العائلة",
        "emailaddress":"عنوان البريد الإلكتروني",
        "phone":"هاتف",
        "country":"البلد / المنطقة",
        "state":"ولاية",
        "city":"المدينة / المدينة",
        "address":".عنوان الشارع",
        "housenumber":"رقم المنزل واسم الشارع",
        "suiteunit":"شقة، جناح، وحدة، الخ (اختياري)",
        "zipcode":"الرمز البريدي",
        "submit":"يُقدِّم",
        "creditcard":"بطاقة إئتمان",
        "cardnumber":"رقم البطاقة",
        "expirydate":"تاريخ انتهاء الصلاحية",
        "nameoncard":"الاسم على البطاقة",
        "savethiscard":"احفظ هذه البطاقة للمدفوعات المستقبلية",
        "play":"يلعب",
        "emailerror":"خطأ في البريد الإلكتروني",
        "payresluttitle":"نجح الدفع",

        "pleaseenter":"من فضلك أدخل",
        "selectamonth":"الرجاء تحديد شهر",
        "selectthenumber":"الرجاء تحديد عدد الشاشات",
        "selectthepurchase":"الرجاء تحديد نوع الشراء",
        "selectthepayment":"الرجاء تحديد طريقة الدفع",
        "discountcodenotempty":"لا يمكن أن يكون رمز الخصم فارغًا!",
        "detail1":"<p>✅ اشتراك ثابت ومتجدد</p>\n" +
            "<p>✅ 4K+HDR عالي الوضوح، سجل مشاهدة مستقل</p>\n" +
            "<p>✅ التسليم في الوقت الحقيقي</p>\n" +
            "<p>✅ دعم محطة متنقلة، وأجهزة الكمبيوتر، والتلفزيون، وصندوق التلفزيون</p>\n"+
            "<p>✅ دعم ما بعد البيع، استرداد الأموال دون قلق</p>",
        "detail2":"<p>✅ اشتراك ثابت، رسوم مدفوعة مسبقًا لمدة 12 شهرًا</p>\n" +
            "<p>✅ الاشتراك المشترك الرسمي</p>\n" +
            "<p>✅ التسليم في الوقت الحقيقي</p>\n" +
            "<p>✅ دعم ما بعد البيع، استرداد الأموال دون قلق</p>",
        "detail3":"<p>✅ اشتراك ثابت، رسوم مدفوعة مسبقًا لمدة 12 شهرًا</p>\n" +
            "<p>✅ تقييمات المحتوى: 18+/R18(21)/TV-MA</p>\n" +
            "<p>✅ التسليم في الوقت الحقيقي</p>\n" +
            "<p>✅ دعم أجهزة متعددة: الهاتف المحمول، الكمبيوتر، التلفزيون</p>\n" +
            "<p>✅ دعم ما بعد البيع، استرداد الأموال دون قلق</p>",
        "detail5":"<p>✅ استخدم اشتراكك الشخصي على YouTube للانضمام إلى مجموعة عائلية متميزة</p>\n" +
            "<p>✅ إرسال رابط الدعوة</p>\n" +
            "<p>✅ احتفظ بقوائمك واشتراكاتك</p>\n" +
            "<p>✅ دعم ما بعد البيع، استرداد الأموال دون قلق</p>\n"+
            "<p>✅ يدعم Premium شخصًا واحدًا فقط، ولا يمكنك دعوة أقاربك أو أصدقائك</p>\n"
        // "<p>✅ Help: Before purchasing, check play.google.com if your account country is ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG , CZ, SE, PT, BE, IL.</p>"
        ,
        "detail7":"<p>✅ 6 أشخاص يتشاركون اشتراك واحد</p>\n" +
            "<p>✅ شات جي بي تي بلس 4.0</p>\n" +
            "<p>✅ استمتع بـ GPTs</p>\n" +
            "<p>✅ متاح للمكونات الإضافية</p>\n" +
            "<p>✅ حظر API</p>\n" +
            "<p>✅ لا داعي للقلق بشأن استرداد الأموال خلال 24 ساعة</p>",

        "detail8":"<p>✅ 4 أشخاص يتشاركون اشتراك واحد</p>\n" +
            "<p>✅ التسليم في الوقت الحقيقي</p>\n" +
            "<p>✅ دعم محطة متنقلة، وأجهزة الكمبيوتر، والتلفزيون، وصندوق التلفزيون</p>\n" +
            "<p>✅ دعم ما بعد البيع، استرداد الأموال دون قلق</p>",
        "detail10":"<p>✅ الخطة المميزة، 3.88 دولار شهريًا/شاشة</p>\n" +
            "<p>✅ إرسال رابط الدعوة في الوقت الحقيقي</p>\n" +
            "<p>✅ الاشتراك الخاص، والحفاظ على كل شيء</p>\n" +
            "<p>✅ المفضلة لديك والقوائم</p>\n" +
            "<p>✅ الضمان، ضمان استرداد الأموال</p>",

        "title":"يرجى تأكيد معلومات المنتج",
        "price":"سعر",
        "product":"اسم المنتج",
        "note":"ملحوظة",
        "notecontent":"تتم مشاركة جميع اشتراكاتنا وبيعها كحسابات فرعية، يرجى التأكد من فهم ذلك قبل الشراء.",
        "notegpt":"هذا حساب مشترك مع 6 أشخاص، والسياسة الرسمية هي طرح 40 سؤالاً خلال 3 ساعات. إذا تم الوصول إلى الحد الأقصى للأسئلة، فمن المستحسن الانتظار لفترة من الوقت لطرح السؤال مرة أخرى. توفر NFXBUS خدمة GPT Plus الرسمية. ترتبط سرعة إنشاء GPT بسرعة الإنترنت لديك.",
        "notenetflix":"إذا قمت بشراء عضوية Netflix Premium، فيرجى مراجعة رقم حسابك وكلمة المرور واتباع المطالبات لاستخدام الموقع الصحيح في صفحة الاشتراكات. بمجرد وصولك إلى موقعك، قم بتعيين دبوس لقفل معلوماتك لمنع الآخرين من استخدامها. إذا تغيرت كلمة المرور، يرجى تسجيل الدخول إلى صفحة الاشتراك في NFXBUS لعرض كلمة مرور الحساب الجديد.",
        "notespotify":"إذا قمت بشراء عضوية Spotify Premium، فيرجى التحقق من رقم حسابك وكلمة المرور في صفحة \"الاشتراك\".",
        "notedisney":"إذا قمت بشراء عضوية Disney+ Premium، فيرجى التحقق من رقم حسابك وكلمة المرور واتباع المطالبات لاستخدام الموقع الصحيح في صفحة \"الاشتراك\". بمجرد وصولك إلى موقعك، قم بتعيين دبوس لقفل معلوماتك لمنع الآخرين من استخدامها. إذا تغيرت كلمة المرور، يرجى تسجيل الدخول إلى صفحة الاشتراك في NFXBUS لعرض كلمة مرور الحساب الجديد.",
        "noteyoutube":"إذا اشتريت YouTube Premium، فيرجى إرسال بريدك الإلكتروني لتسجيل الدخول إلى YouTube في صفحة \"الاشتراك\". بعد الإرسال، سيرسل الموظفون رابط دعوة لمجموعة عائلة YouTube Premium إلى بريدك الإلكتروني. يرجى التحقق من عنوان بريدك الإلكتروني والنقر للقبول.",
    },
    login:{
        "welcomelogin":"مرحبا بكم في تسجيل الدخول",
        "login":"تسجيل الدخول",
        "updatepwd":"تغيير كلمة المرور",
        "reg":"الحساب المسجل",
        "agreement":", أنت توافق على شروط المستخدم الخاصة بنا وتؤكد أنك قد قرأت سياسة الخصوصية الخاصة بنا",
        "quicklogin":"تسجيل الدخول السريع",
        "sendcode":"إرسال الرمز",
        "register":"يسجل",
        "changepassword":"تغيير كلمة المرور",
        "verificationcode":"رمز التحقق",
        "name":"اسم",
        "password":"كلمة المرور",
        "agapassword":"كرر كلمة المرور",
        "modify":"يُعدِّل",
        "validatepswlen":"تتكون كلمة المرور من حروف كبيرة وصغيرة وأرقام وأحرف خاصة، أي مجموعة مكونة من ثلاثة منها، ويكون طولها بين 8 و16!",
        "checkagreement":"يرجى التحقق من الاتفاقية",
        "passwordinconsistency":"كلمات المرور التي تم إدخالها مرتين غير متناسقة!",
        "passwordagain":"الرجاء إدخال كلمة المرور مرة أخرى",
        "verificationsend":"تم إرسال رمز التحقق، انتبه للتأكد",
    },
    luckdraw:{
        "lotteryticket":"تذكرة اليانصيب",
        "integral":"أساسي",
        "welcome":"مرحبًا بكم في nfxbus، هذه هي صفحة نشاطنا، انقر على البطاقة لسحب الجوائز بنقرة واحدة،" +
            " تشمل الجوائز باقات حسابات مجانية متنوعة وأكواد خصم وخصومات أخرى، شكرًا لدعمكم وثقتكم." ,
        "none":"لا قيمة لها",
        "redemptioncode":"استرداد الرمز",
        "copy":"ينسخ",
        "prizelist":"قائمة الجوائز",
        "notused":"غير مستخدم",
        "used":"مستخدم",
        "expired":"منتهي الصلاحية",

        "prizeno":"رقم الجائزة",
        "contents":"محتويات",
        "exchangedate":"تاريخ الصرف",
        "createtime":"خلق الوقت",
        "exchange":"تبادل",
        "insufficientpoints":"نقاط غير كافية",
        "consumepoints":"سوف تستهلك هذه العملية 10 نقاط. يكمل؟",
        "tips":"نصائح",
        "comfirm":"يتأكد",
        "cancel":"يلغي",
        "failtowin":"عدم الفوز في اليانصيب",
        "congratulations":"مبروك الحصول على",
        "copysucceeded":"تم النسخ بنجاح!",
        "copyfailed":"فشل النسخ!",

    },
    subscribe:{
        "renewnow":"جدد الآن",
        "subscription":"تجديد نفس الاشتراك دون تغيير",
        "independent":"4K+HDR HD، سجل مشاهدة مستقل",
        "guarantee":"ضمان استعادة الاموال",
        "timeleft":"الوقت المتبقي",
        "openingtime":"وقت فتح الحساب",
        "endtime":"وقت النهاية",
        "account":"حساب",
        "password":"كلمة المرور",
        "profile":"حساب تعريفي",
        "pleaseuse":"يرجى استخدام",
        "subaccount":"حساب فرعي",
        "myprofile":"لا تشغل منصب شخص آخر!",
        "renew":"تجديد",
        "aftersales":"ما بعد البيع",

        "yourownsub":"1.يرجى استخدام حسابك الفرعي الخاص وفقًا للرقم التسلسلي",
        "delete":"2. لا تقم بتعديل/حذف الحسابات الفرعية الأخرى",
        "modifysubscription":"3. لا تقم بتعديل المعلومات المتعلقة بالاشتراك",
        "reminder":"تذكير: إذا واجهت أي مشاكل، يرجى الاتصال بخدمة العملاء في الوقت المناسب، وشراء اشتراك في nfxbus، والاستمتاع بخدمة عملاء VIP، ولا تحتاج إلى تغيير الرقم للتجديد.",

        "youtubeloginemail":"الرجاء إدخال البريد الإلكتروني لتسجيل الدخول إلى YouTube. يجب عليك التسجيل في مجموعة المشاركة المنزلية باستخدام عنوان البريد الإلكتروني لتسجيل الدخول، وإلا فسيتم طردك من المنزل. إذا كنت بحاجة إلى مساعدة، يرجى الاتصال بخدمة عملاء Nfxbus عبر البريد الإلكتروني.",
        "inspectaddress":"يرجى التحقق من بلد/منطقة حسابك: KR، ES، FR، PL، IT، DE، NL، GB، CA، US، JP، GR، CH، LT، PA، SG، CZ، SE، PT، BE، Illinois. إذا واجهت أي مشاكل، يرجى التواصل مع الموظفين من خلال التذاكر خلال الوقت المحدد.",
        "aftersalesservice":"خدمة ما بعد البيع",
        "support":"صفحة الدعم.",
        "submit":"يُقدِّم",

        "buyit":"لا توجد اشتراكات حتى الآن، يرجى المسارعة لشرائه",
        "paymentsucceeded":"نجح الدفع",
        "month":"الرجاء تحديد شهر",
        "number":"الرجاء تحديد عدد الشاشات",
        "purchase":"الرجاء تحديد نوع الشراء",
        "payment":"الرجاء تحديد طريقة الدفع",
        "name":"الرجاء إدخال الاسم على البطاقة",
        "card":"الرجاء إدخال رقم البطاقة",
        "cvv":"الرجاء إدخال CVV",
        "expirydate":"الرجاء إدخال تاريخ انتهاء الصلاحية",
        "processing":"تم إرسال حالة المعالجة. التحقق من حالة المعالجة في أمر العمل.",
        "cannotempty":"لا يمكن أن يكون رمز الخصم فارغًا!",
        "remind":"ما إذا كان سيتم إرسال رسالة",
        "originalpassword":"لن يتم استخدام كلمة المرور الأصلية لهذه العملية بعد الآن، هل يجب الاستمرار؟",
        "passwordmeets":"تتكون كلمة المرور من حروف كبيرة وصغيرة وأرقام وأحرف خاصة، أي مجموعة مكونة من ثلاثة منها، ويكون طولها بين 8 و16!",
        "pwdupdatetime":"تغيير كلمة المرور",
        "pwdupdatetips":"تم الإرسال، يرجى الانتظار بصبر",

        "getverlink":"الحصول على رابط التحقق من تسجيل الدخول",
        "ignorefeature":"إذا لم يُطلب منك ملء رمز التحقق، فيرجى تجاهل هذه الميزة.",
        "openlink":"افتح الرابط",
        "clicklink":"انقر للحصول على الرابط",
    },
    userinfo:{
        "signout":"تسجيل الخروج",
        "nickname":"كنية",
        "email":"بريد إلكتروني",
        "code":"شفرة",
        "vercode":"رمز التحقق",
        "avatar":"الصورة الرمزية",
        "uploadavatar":"يرجى تحميل الصورة الرمزية الخاصة بك",
        "logout":"هل أنت متأكد أنك تريد تسجيل الخروج؟",
        "onlyjpgpng":"الصور المرفوعة يمكن أن تكون فقط بصيغة JPG | تنسيق بابوا نيو غينيا",
        "cannotexceed":"لا يمكن أن يتجاوز حجم الصورة المرفوعة 5 ميغابايت!"
    },
    wish:{
        "wish":"يتمنى",
        "help":"سوف تختار Nfxbus بعض الرغبات للمساعدة",
        "christmas":"أنها تتحقق عندما يأتي عيد الميلاد، اترك رغبتك",
        "submit":"يُقدِّم",
        "mywish":"رغبتي"
    },
    map:{
        "home":"بيت",
        "userinfo":"معلومات المستخدم",
        "login":"تسجيل الدخول",
        "withfriends":"مع الأصدقاء",
        "subscribe":"يشترك",
        "aftersales":"ما بعد البيع",
        "luckdraw":"رسم محظوظ",
        "wish":"يتمنى",
        "helpcenter":"مركز المساعدة",
        "tradingstamps":"طوابع التداول",
    },
}

export default ar
