const pt = {
    aftersales:{
        "contactus":"Entre em contato conosco para uma resposta rápida",
        "workorder":"Lista de ordens de serviço",
        "createjob":"Criar um trabalho",
        "submittinworkorder":"Ao enviar uma ordem de serviço, inclua o máximo de informações detalhadas possível, como capturas de tela de erros, informações da conta, etc., que são muito importantes para que possamos lidar com os problemas rapidamente.",
        "workordercontent":"Conteúdo da ordem de serviço",
        "workorderreply":"Resposta da ordem de serviço",
        "subscriptionaccount":"Conta de assinatura",
        "problemtype":"Tipo de problema",
        "tobeprocessed":"Para ser processado",
        "reply":"responder",
        "processed":"Processado,Ver respostas",
        "revoked":"Revogado",
        "create":"Criar",
        "workorderno":"Ordem de serviço nº",
        "product":"Produto",
        "status":"Status",
        "type":"Tipo",
        "ticket":"Bilhete",
        "createtime":"criarHora",
    },
    excode:{
        "redemptioncode":"Resgatar código",
        "pleaseredemptioncode":"Por favor, insira o código de resgate",
        "exchange":"Usar",
        "notused":"não usado",
        "used":"Usado",
        "expired":"Expirado",
        "status":"Status",
        "exchangetime":"Data de troca",
        "remark":"Observação",
        "createtime":"criarHora",
    },
    footer:{
        "contactus":"Contate-nos",
        "navigation":"Navegação",
        "home":"Lar",
        "friends":"Amigos",
        "subscribe":"Inscrever-se",
        "aftersales":"Pós-venda",
        "help":"Ajuda",
        "termsofuse":"Termos de Uso",
        "refundpolicy":"Política de Reembolso",
        "privacypolicy":"política de Privacidade",
        "paymentmethod":"Método de pagamento",
        "language":"Linguagem",
    },
    friends:{
        "promotioninfo":"Informações promocionais",
        "currentlevel":"nível atual",
        "content":"Você pode usar o link de convite exclusivo e códigos de desconto para compartilhar com outros e ganhar comissões. Quanto maior o nível, maior a comissão compartilhada",
        "rolemembers":"O papel dos membros",
        "promotionallinks":"Link de convite",
        "discountcodes":"Códigos de desconto",
        "copy":"cópia",
        "rebateratio":"Taxa de comissão",
        "discountratio":"Taxa de desconto",
        "lowestdiscountratio":"Desconto de assinatura de usuário",
        "walletsandpoints":"Carteiras e Congelar valor",
        "applywithdrawal":"Após você solicitar o saque, revisaremos e efetuaremos o pagamento em até 3 dias úteis",
        "withdraw":"Retirar",
        "record":"Registro",
        "integral":"Integrante",
        "wallet":"Carteiras",
        "freezeamount":"Congelar",
        "pointsused":"Produtos do YouTube congelados por 3 dias, outros produtos congelados por 1 dia",
        "orderdetails":"Detalhes do pedido",
        "orderamount":"Valor do pedido",
        "earnings":"Ganhos",
        "withdrawalsrecord":"Registro de retiradas",
        "accounts":"Contas",
        "accounttype":"Tipo de conta",
        "withdrawalamount":"Quantia",
        "cancel":"Cancelar",
        "submit":"Enviar",

        "greaterthan":"O valor deve ser maior que 0!",
        "normal":"normal",
        "invalid":"inválido",
        "freeze":"Congelar",
        "withdrawing":"Retirado",
        "extracted":"Extraído",

        "bankcard":"cartão bancário",
        "promotionlink":"Link de promoção",
        "discountcode":"Código de desconto",
        "waitingforapproval":"Aguardando aprovação",
        "waitingforpayment":"Aguardando pagamento",
        "notpass":"não passar",
        "paid":"Pago",

        "cashwithdrawalid":"ID de retirada de dinheiro",
        "accountsreceivable":"Contas a receber",
        "amount":"Quantidade (USD)",
        "status":"status",
        "applicationtime":"Tempo de aplicação",
        "processingresults":"Processando resultados",

        "orderid":"ID do pedido",
        "useramount":"Quantidade de usuários",
        "duration":"duração",
        "promotiontype":"tipo de promoção",
        "createtime":"criarHora",
        "eainings":"Lucros (USD)",

        "receivingaccount":"conta de recebimento",
        "nowithdrawableamount":"Retirada mínima de 30 USD",
        "copysuccessfully":"Copiado com sucesso",
        "copyfailed":"Falha na cópia",
        "activetime":"Tempo ativo",
    },
    home:{
        'join':'Juntar',
        "texttitle":"Assinatura premium compartilhada com preço mais baixo em",
        "buynow":"COMPRE AGORA",
        "soldout":"FALTA DE ESTOQUE",
        "shoptitle":"Após fazer o pedido, verifique a mensagem de e-mail do e-mail de login atual (certifique-se de que o endereço de e-mail esteja correto)",
        "delivery":"ENTREGA EM TEMPO REAL",
        "after":"Entrega em tempo real após o pagamento sem espera",
        "certificate":"CERTIFICADO SSI",
        "payments":"Os pagamentos ocorrem em um ambiente seguro com um certificado de segurança SSL",
        "onlinesupport":"SUPORTE AO VIVO 24/7",
        "privatecustomer":"A Nfxbus oferece atendimento privado ao cliente online 24 horas por dia, 7 dias por semana",
        "subscriber":"CO-ASSINANTE ADEQUADO",
        "subscriptions":"Compartilhe assinatura premium a um preço menor. Prioridade de correspondências para co-assinantes do mesmo país",
        "moneyback":"GARANTIA DE REEMBOLSO",
        "refund":"Você tem de 1 a 3 dias para solicitar um reembolso (YouTube 3 dias, outros produtos 24 horas)",
        "pwdreset":"RESET RÁPIDO DE SENHA",
        "pwdresetdesc":"a senha não funciona? Clique em redefinir senha na página de assinatura sem esperar e operação manual",
        "parkingspace":"vaga de estacionamento",
        "gift":"presente",
        "screen":"Tela",
        "1screen":"1 perfil (compartilhado)",
        "maxnum":"perfis (privados)",
        "month":"Mês",
        "buytyp":"Tipo de compra",
        "subscription":"subscrição",
        "voucher":"Voucher (compra de renovação)",
        "discountcode":"Código de desconto",
        "use":"Usar",
        "paymentmethod":"Método de pagamento",
        "pleasechoose":"por favor escolha",
        "billingdetails":"Detalhes de cobrança",
        "payaddress":"Informações de endereço de pagamento",
        "firstname":"Primeiro nome",
        "lastname":"Sobrenome",
        "emailaddress":"Endereço de email",
        "phone":"Telefone",
        "country":"País / Região",
        "state":"estado",
        "city":"Cidade / Vila",
        "address":"endereço da Rua",
        "housenumber":"Número da casa e nome da rua",
        "suiteunit":"Apartamento, suíte, unidade, etc. (opcional)",
        "zipcode":"CEP",
        "submit":"Enviar",
        "creditcard":"Cartão de crédito",
        "cardnumber":"Número do cartão",
        "expirydate":"Data de validade",
        "nameoncard":"Nome no cartão",
        "savethiscard":"Guarde este cartão para pagamentos futuros",
        "play":"Jogar",
        "emailerror":"E-mail ERRO",
        "payresluttitle":"Pagamento efetuado com sucesso",

        "pleaseenter":"por favor entre",
        "selectamonth":"Selecione um mês",
        "selectthenumber":"Selecione o número de telas",
        "selectthepurchase":"Selecione o tipo de compra",
        "selectthepayment":"Selecione o método de pagamento",
        "discountcodenotempty":"O código de desconto não pode estar vazio!",
        "detail1":"<p>✅ Assinatura estável e renovável</p>\n" +
            "<p>✅ Gravação de visualização independente de alta definição 4K+HDR</p>\n" +
            "<p>✅ Entrega em tempo real</p>\n" +
            "<p>✅ Suporte terminal móvel, PC, TV, caixa de TV</p>\n"+
            "<p>✅ Suporte pós-venda, reembolso sem preocupações</p>",
        "detail2":"<p>✅ Assinatura estável, taxa pré-paga de 12 meses</p>\n" +
            "<p>✅ Assinatura oficial compartilhada</p>\n" +
            "<p>✅ Entrega em tempo real</p>\n" +
            "<p>✅ Suporte pós-venda, reembolso sem preocupações</p>",
        "detail3":"<p>✅ Assinatura estável, taxa pré-paga de 12 meses</p>\n" +
            "<p>✅ Classificação de conteúdo: 18+/R18(21)/TV-MA</p>\n" +
            "<p>✅ Entrega em tempo real</p>\n" +
            "<p>✅ Suporta vários dispositivos: celular, computador, TV</p>\n" +
            "<p>✅ Suporte pós-venda, reembolso sem preocupações</p>",
        "detail5":"<p>✅ Use sua assinatura pessoal do YouTube para ingressar no grupo familiar premium</p>\n" +
            "<p>✅ Enviar link de convite</p>\n" +
            "<p>✅ Mantenha suas listas e assinaturas</p>\n" +
            "<p>✅ Suporte pós-venda, reembolso sem preocupações</p>\n"+
            "<p>✅ Premium suporta apenas 1 pessoa, você não pode convidar seus parentes ou amigos</p>\n"
        // "<p>✅ Help: Before purchasing, check play.google.com if your account country is ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG , CZ, SE, PT, BE, IL.</p>"
        ,
        "detail7":"<p>✅ 6 pessoas compartilham 1 assinatura</p>\n" +
            "<p>✅ ChatGPT Plus 4.0</p>\n" +
            "<p>✅ Aproveite os GPTs</p>\n" +
            "<p>✅ Disponível para plugins</p>\n" +
            "<p>✅ Banir API</p>\n" +
            "<p>✅ Reembolso sem preocupações em 24 horas</p>",

        "detail8":"<p>✅ 4 pessoas compartilham 1 assinatura</p>\n" +
            "<p>✅ Entrega em tempo real</p>\n" +
            "<p>✅ Suporte terminal móvel, PC, TV, caixa de TV</p>\n" +
            "<p>✅ Suporte pós-venda, reembolso sem preocupações</p>",
        "detail10":"<p>✅ Plano Premium, US$ 3,88/mês/tela</p>\n" +
            "<p>✅ Enviar link de convite em tempo real</p>\n" +
            "<p>✅ Assinatura privada, mantenha tudo</p>\n" +
            "<p>✅ Seus favoritos e listas</p>\n" +
            "<p>✅ Garantia, garantia de reembolso</p>",

        "title":"Por favor confirme as informações do produto",
        "price":"Preço",
        "product":"Nome do produto",
        "note":"OBSERVAÇÃO",
        "notecontent":"Todas as nossas assinaturas são compartilhadas e vendidas como subcontas. Certifique-se de entender isso antes de comprar.",
        "notegpt":"Esta é uma conta compartilhada com 6 pessoas, e a política oficial é fazer 40 perguntas em 3 horas. Se o limite de perguntas for atingido, é recomendado esperar um período de tempo para perguntar novamente. O NFXBUS fornece o GPT Plus oficial. A velocidade de geração do GPT está relacionada à sua velocidade de internet.",
        "notenetflix":"Se você adquiriu uma assinatura Netflix Premium, revise seu número de conta, senha e siga as instruções para usar o local correto na página Assinaturas. Quando estiver em seu local, defina um PIN para bloquear suas informações e impedir que outras pessoas as usem. Se a senha mudar, faça login na página de assinatura do NFXBUS para visualizar a nova senha da conta.",
        "notespotify":"Se você adquiriu a assinatura Spotify Premium, verifique seu número de conta e senha na página \"Assinatura\"",
        "notedisney":"Se você adquiriu uma assinatura premium do Disney+, verifique seu número de conta, senha e siga as instruções para usar o local correto na página \"Assinatura\". Quando estiver em seu local, defina um PIN para bloquear suas informações e impedir que outras pessoas as usem. Se a senha mudar, faça login na página de assinatura do NFXBUS para visualizar a nova senha da conta.",
        "noteyoutube":"Se você comprou o YouTube Premium, envie seu e-mail de login do YouTube na página \"Assinatura\". Após o envio, a equipe enviará um link de convite para o grupo familiar do YouTube Premium para seu e-mail. Verifique seu endereço de e-mail e clique para aceitar.",
    },
    login:{
        "welcomelogin":"Bem-vindo ao Login",
        "login":"Conecte-se",
        "updatepwd":"Alterar a senha",
        "reg":"Conta Registrada",
        "agreement":", você concorda com nossos Termos de Usuário e confirma que leu nossa Política de Privacidade",
        "quicklogin":"Login rápido",
        "sendcode":"Enviar código",
        "register":"Registrar",
        "changepassword":"Alterar a senha",
        "verificationcode":"Código de Verificação",
        "name":"Nome",
        "password":"Senha",
        "agapassword":"Repita a senha",
        "modify":"Modificar",
        "validatepswlen":"A senha contém letras maiúsculas e minúsculas, números e caracteres especiais, qualquer combinação de três deles, e o comprimento está entre 8 e 16!",
        "checkagreement":"Por favor, verifique o acordo",
        "passwordinconsistency":"As senhas inseridas duas vezes são inconsistentes!",
        "passwordagain":"por favor digite a senha novamente",
        "verificationsend":"O código de verificação foi enviado, preste atenção para verificar",
    },
    luckdraw:{
        "lotteryticket":"Bilhete de loteria",
        "integral":"integrante",
        "welcome":"Bem-vindo ao nfxbus, esta é a nossa página de atividades, clique no cartão para sortear prêmios com um clique, os prêmios incluem vários pacotes de conta gratuita, códigos de desconto e outros descontos, obrigado pelo seu apoio e confiança.",
        "none":"sem prêmio",
        "redemptioncode":"Resgatar código",
        "copy":"cópia",
        "prizelist":"Lista de prêmios",
        "notused":"não usado",
        "used":"Usado",
        "expired":"Expirado",

        "prizeno":"Prêmio No.",
        "contents":"Conteúdo",
        "exchangedate":"Data de troca",
        "createtime":"criarHora",
        "exchange":"intercâmbio",
        "insufficientpoints":"Pontos insuficientes",
        "consumepoints":"Esta operação consumirá 10 pontos. Continuar?",
        "tips":"Pontas",
        "comfirm":"confirmar",
        "cancel":"cancelar",
        "failtowin":"Não ganhar na loteria",
        "congratulations":"Parabéns pela obtenção",
        "copysucceeded":"Copiado com sucesso!",
        "copyfailed":"Falha na cópia!",

    },
    subscribe:{
        "renewnow":"Renovar agora",
        "subscription":"Renovar a mesma assinatura sem alterações",
        "independent":"4K+HDR HD, gravação de visualização independente",
        "guarantee":"garantia de devolução de dinheiro",
        "timeleft":"Tempo restante",
        "openingtime":"Horário de abertura da conta",
        "endtime":"Fim dos tempos",
        "account":"Conta",
        "password":"Conta",
        "profile":"Perfil",
        "pleaseuse":"por favor use",
        "subaccount":"subconta",
        "myprofile":"Não ocupe a posição de outra pessoa!",
        "renew":"Renovar",
        "aftersales":"Pós-venda",

        "yourownsub":"1.Por favor, use sua própria subconta de acordo com o número de série",
        "delete":"2.Não modifique/exclua outras subcontas",
        "modifysubscription":"3.Não modifique informações relacionadas à assinatura",
        "reminder":"Lembrete: se você encontrar algum problema, entre em contato com o atendimento ao cliente a tempo, compre uma assinatura na nfxbus, aproveite o atendimento ao cliente VIP e não precise alterar o número para renovação.",

        "youtubeloginemail":"Insira seu e-mail de login do YouTube. Você deve se registrar para um grupo doméstico usando o endereço de e-mail de login, caso contrário, você será expulso de casa. Se precisar de assistência, entre em contato com o serviço de atendimento ao cliente da Nfxbus por e-mail.",
        "inspectaddress":"Verifique o país/região da sua conta: KR, ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG, CZ, SE, PT, BE, Illinois. Se você encontrar algum problema, entre em contato com os funcionários por meio de tickets dentro do tempo designado.",
        "aftersalesservice":"serviço pós-venda",
        "support":"página de suporte.",
        "submit":"Enviar",

        "buyit":"Ainda não há assinaturas, por favor, corra para comprá-las",
        "paymentsucceeded":"Pagamento efetuado com sucesso",
        "month":"Selecione um mês",
        "number":"Selecione o número de telas",
        "purchase":"Selecione o tipo de compra",
        "payment":"Selecione o método de pagamento",
        "name":"Por favor, insira o nome no cartão",
        "card":"Por favor, insira o número do cartão",
        "cvv":"Por favor, insira o CVV",
        "expirydate":"Por favor, insira a data de validade",
        "processing":"O status de processamento foi enviado. Verifique o status de processamento na ordem de serviço.",
        "cannotempty":"O código de desconto não pode estar vazio!",
        "remind":"Se deve enviar mensagem",
        "originalpassword":"A senha original para esta operação não será mais utilizada. Deseja continuar?",
        "passwordmeets":"A senha contém letras maiúsculas e minúsculas, números e caracteres especiais, qualquer combinação de três deles, e o comprimento está entre 8 e 16!",
        "pwdupdatetime":"Mudança de senha",
        "pwdupdatetips":"Enviado, aguarde pacientemente",

        "getverlink":"Obter link de verificação de login",
        "ignorefeature":"Se você não foi solicitado a preencher o código de verificação, ignore esse recurso.",
        "openlink":"Abra o link",
        "clicklink":"Clique para obter o link",
    },
    userinfo:{
        "signout":"sair",
        "nickname":"Apelido",
        "email":"E-mail",
        "code":"Código",
        "vercode":"Código de verificação",
        "avatar":"Avatar",
        "uploadavatar":"Por favor, carregue seu avatar",
        "logout":"Tem certeza de que deseja sair?",
        "onlyjpgpng":"As imagens enviadas só podem estar no formato JPG | png",
        "cannotexceed":"O tamanho da imagem enviada não pode exceder 5 MB!"
    },
    wish:{
        "wish":"Desejar",
        "help":"Nfxbus escolherá alguns desejos para ajudar",
        "christmas":"eles se realizam quando o Natal chega, deixe seu desejo",
        "submit":"Enviar",
        "mywish":"meu desejo"
    },
    map:{
        "home":"Lar",
        "userinfo":"Informações do usuário",
        "login":"Conecte-se",
        "withfriends":"Com amigos",
        "subscribe":"Inscrever-se",
        "aftersales":"Pós-venda",
        "luckdraw":"Sorteio",
        "wish":"Desejar",
        "helpcenter":"Central de Ajuda",
        "tradingstamps":"selos comerciais",
    },
}

export default pt
