const it = {
    aftersales:{
        "contactus":"Contattaci per una risposta rapida",
        "workorder":"Elenco degli ordini di lavoro",
        "createjob":"Crea un lavoro",
        "submittinworkorder":"Quando si invia un ordine di lavoro, si prega di includere quante più informazioni dettagliate possibili, come schermate di errore, informazioni sull'account, ecc., che sono molto importanti per consentirci di gestire rapidamente i problemi.",
        "workordercontent":"Contenuto dell'ordine di lavoro",
        "workorderreply":"Rispondi all'ordine di lavoro",
        "subscriptionaccount":"Conto abbonamento",
        "problemtype":"Tipo di problema",
        "tobeprocessed":"Da elaborare",
        "reply":"rispondere",
        "processed":"Elaborato,Visualizza risposte",
        "revoked":"Revoca",
        "create":"Creare",
        "workorderno":"Ordine di lavoro n.",
        "product":"Prodotto",
        "status":"Stato",
        "type":"Tipo",
        "ticket":"Biglietto",
        "createtime":"creareTempo",
    },
    excode:{
        "redemptioncode":"Riscatta il codice",
        "pleaseredemptioncode":"Inserisci il codice di riscatto",
        "exchange":"Utilizzo",
        "notused":"non utilizzato",
        "used":"Usato",
        "expired":"Scaduto",
        "status":"Stato",
        "exchangetime":"Data di cambio",
        "remark":"Osservazione",
        "createtime":"creareTempo",
    },
    footer:{
        "contactus":"contattaci",
        "navigation":"Navigazione",
        "home":"Casa",
        "friends":"Amici",
        "subscribe":"Iscriviti",
        "aftersales":"Post-vendita",
        "help":"Aiuto",
        "termsofuse":"Termini di utilizzo",
        "refundpolicy":"Politica di rimborso",
        "privacypolicy":"politica sulla riservatezza",
        "paymentmethod":"Metodo di pagamento",
        "language":"Lingua",
    },
    friends:{
        "promotioninfo":"Informazioni sulla promozione",
        "currentlevel":"livello attuale",
        "content":"Puoi usare link di invito esclusivi e codici sconto da condividere con altri per guadagnare commissioni. Più alto è il livello, più alta è la quota di commissione",
        "rolemembers":"Il ruolo dei membri",
        "promotionallinks":"Link di invito",
        "discountcodes":"Codici sconto",
        "copy":"copia",
        "rebateratio":"Tasso di commissione",
        "discountratio":"Rapporto di sconto",
        "lowestdiscountratio":"Sconto abbonamento utente",
        "walletsandpoints":"Portafogli e importo bloccato",
        "applywithdrawal":"Dopo aver richiesto il prelievo, esamineremo la richiesta ed effettueremo il pagamento entro 3 giorni lavorativi",
        "withdraw":"Ritirare",
        "record":"Documentazione",
        "integral":"Integrante",
        "wallet":"Portafogli",
        "freezeamount":"Congelare",
        "pointsused":"Prodotti YouTube congelati per 3 giorni, altri prodotti congelati per 1 giorno",
        "orderdetails":"Dettagli dell'ordine",
        "orderamount":"Importo dell'ordine",
        "earnings":"Guadagni",
        "withdrawalsrecord":"Registro prelievi",
        "accounts":"Conti",
        "accounttype":"Tipo di account",
        "withdrawalamount":"Quantità",
        "cancel":"Cancellare",
        "submit":"Invia",

        "greaterthan":"L'importo deve essere maggiore di 0!",
        "normal":"normale",
        "invalid":"non valido",
        "freeze":"Congelare",
        "withdrawing":"Ritiro",
        "extracted":"Estratto",

        "bankcard":"carta di credito",
        "promotionlink":"Link di promozione",
        "discountcode":"Codice sconto",
        "waitingforapproval":"In attesa di approvazione",
        "waitingforpayment":"In attesa del pagamento",
        "notpass":"non passare",
        "paid":"Pagato",

        "cashwithdrawalid":"ID prelievo contanti",
        "accountsreceivable":"Crediti commerciali",
        "amount":"Importo (USD)",
        "status":"stato",
        "applicationtime":"Tempo di applicazione",
        "processingresults":"Elaborazione dei risultati",

        "orderid":"ID ordine",
        "useramount":"Importo utente",
        "duration":"durata",
        "promotiontype":"tipo di promozione",
        "createtime":"creareTempo",
        "eainings":"Guadagni (USD)",

        "receivingaccount":"conto di ricezione",
        "nowithdrawableamount":"Prelievo minimo di 30 USD",
        "copysuccessfully":"Copiato correttamente",
        "copyfailed":"Copia non riuscita",
        "activetime":"Tempo attivo",
    },
    home:{
        'join':'Giuntura',
        "texttitle":"Abbonamento premium condiviso con prezzo più basso su",
        "buynow":"ACQUISTA ORA",
        "soldout":"CARENZA DI SCORTE",
        "shoptitle":"Dopo aver effettuato l'ordine, controlla il messaggio e-mail dell'indirizzo e-mail di accesso corrente (assicurati che l'indirizzo e-mail sia corretto)",
        "delivery":"CONSEGNA IN TEMPO REALE",
        "after":"Consegna in tempo reale dopo il pagamento senza attesa",
        "certificate":"CERTIFICATO SSL",
        "payments":"I pagamenti avvengono in un ambiente sicuro con certificato di sicurezza SSL",
        "onlinesupport":"SUPPORTO LIVE 24/7",
        "privatecustomer":"Nfxbus fornisce un servizio clienti privato online 24 ore su 24, 7 giorni su 7",
        "subscriber":"CO-ABBONATO IDONEO",
        "subscriptions":"Condividi l'abbonamento premium a un prezzo più basso. Corrispondenze prioritarie per i co-abbonati dello stesso paese",
        "moneyback":"GARANZIA DI RIMBORSO",
        "refund":"Hai 1-3 giorni per richiedere un rimborso (YouTube 3 giorni Altri prodotti 24 ore)",
        "pwdreset":"RIPRISTINO VELOCE DELLA PASSWORD",
        "pwdresetdesc":"la password non funziona? Fai clic su reimposta pwd nella pagina di iscrizione senza attendere e senza operazioni manuali",
        "parkingspace":"posto auto",
        "gift":"regalo",
        "screen":"Schermo",
        "1screen":"1 profilo(condiviso)",
        "maxnum":"profili(privati)",
        "month":"Mese",
        "buytyp":"Acquista Tipo",
        "subscription":"sottoscrizione",
        "voucher":"Voucher(acquisto di rinnovo)",
        "discountcode":"Codice sconto",
        "use":"Utilizzo",
        "paymentmethod":"Metodo di pagamento",
        "pleasechoose":"per favore scegli",
        "billingdetails":"Dettagli di fatturazione",
        "payaddress":"Informazioni sull'indirizzo di pagamento",
        "firstname":"Nome di battesimo",
        "lastname":"Cognome",
        "emailaddress":"Indirizzo e-mail",
        "phone":"Telefono",
        "country":"Paese / Regione",
        "state":"stato",
        "city":"Città / Paese",
        "address":"Indirizzo stradale",
        "housenumber":"Numero civico e nome della via",
        "suiteunit":"Appartamento, suite, unità, ecc. (facoltativo)",
        "zipcode":"Cap",
        "submit":"Invia",
        "creditcard":"Carta di credito",
        "cardnumber":"Numero della carta",
        "expirydate":"Data di scadenza",
        "nameoncard":"Nome sulla carta",
        "savethiscard":"Salva questa carta per i pagamenti futuri",
        "play":"Giocare",
        "emailerror":"ERRORE e-mail",
        "payresluttitle":"Pagamento riuscito",

        "pleaseenter":"per favore inserisci",
        "selectamonth":"Seleziona un mese",
        "selectthenumber":"Seleziona il numero di schermi",
        "selectthepurchase":"Seleziona il tipo di acquisto",
        "selectthepayment":"Seleziona il metodo di pagamento",
        "discountcodenotempty":"Il codice sconto non può essere vuoto!",
        "detail1":"<p>✅ Abbonamento stabile e rinnovabile</p>\n" +
            "<p>✅ Registrazione di visione indipendente ad alta definizione 4K+HDR</p>\n" +
            "<p>✅ Consegna in tempo reale</p>\n" +
            "<p>✅ Supporta terminale mobile, PC, TV, TV box</p>\n"+
            "<p>✅ Assistenza post-vendita, rimborso senza pensieri</p>",
        "detail2":"<p>✅ Abbonamento stabile, canone prepagato 12 mesi</p>\n" +
            "<p>✅ Abbonamento ufficiale condiviso</p>\n" +
            "<p>✅ Consegna in tempo reale</p>\n" +
            "<p>✅ Assistenza post-vendita, rimborso senza pensieri</p>",
        "detail3":"<p>✅ Abbonamento stabile, canone prepagato 12 mesi</p>\n" +
            "<p>✅ Classificazione dei contenuti: 18+/R18(21)/TV-MA</p>\n" +
            "<p>✅ Consegna in tempo reale</p>\n" +
            "<p>✅ Supporta più dispositivi: telefono cellulare, computer, TV</p>\n" +
            "<p>✅ Assistenza post-vendita, rimborso senza pensieri</p>",
        "detail5":"<p>✅ Utilizza il tuo abbonamento YouTube personale per unirti al gruppo famiglia premium</p>\n" +
            "<p>✅ Invia link di invito</p>\n" +
            "<p>✅ Mantieni le tue liste e i tuoi abbonamenti</p>\n" +
            "<p>✅ Assistenza post-vendita, rimborso senza pensieri</p>\n"+
            "<p>✅ Premium supporta solo 1 persona, non puoi invitare parenti o amici</p>\n"
        // "<p>✅ Help: Before purchasing, check play.google.com if your account country is ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG , CZ, SE, PT, BE, IL.</p>"
        ,
        "detail7":"<p>✅ 6 persone condividono 1 abbonamento</p>\n" +
            "<p>✅ Versione 4.0 di ChatGPT Plus</p>\n" +
            "<p>✅ Goditi i GPT</p>\n" +
            "<p>✅ Disponibile per i plugin</p>\n" +
            "<p>✅ Divieto API</p>\n" +
            "<p>✅ Rimborso senza pensieri entro 24 ore</p>",

        "detail8":"<p>✅ 4 persone condividono 1 abbonamento</p>\n" +
            "<p>✅ Consegna in tempo reale</p>\n" +
            "<p>✅ Supporta terminale mobile, PC, TV, TV box</p>\n" +
            "<p>✅ Assistenza post-vendita, rimborso senza pensieri</p>",
        "detail10":"<p>✅ Piano Premium, $ 3,88/mese/schermo</p>\n" +
            "<p>✅ Invia il link di invito in tempo reale</p>\n" +
            "<p>✅ Abbonamento privato, mantieni tutto</p>\n" +
            "<p>✅ I tuoi preferiti e le tue liste</p>\n" +
            "<p>✅ Garanzia, garanzia di rimborso</p>",

        "title":"Si prega di confermare le informazioni sul prodotto",
        "price":"Prezzo",
        "product":"Nome del prodotto",
        "note":"NOTA",
        "notecontent":"Tutti i nostri abbonamenti sono condivisi e venduti come sottoaccount. Assicurati di aver compreso questo prima di procedere all'acquisto.",
        "notegpt":"Questo è un account condiviso con 6 persone e la politica ufficiale è di porre 40 domande entro 3 ore. Se si raggiunge il limite di domande, si consiglia di attendere un periodo di tempo prima di chiedere di nuovo. NFXBUS fornisce il GPT Plus ufficiale. La velocità di generazione del GPT è correlata alla velocità di Internet.",
        "notenetflix":"Se hai acquistato un abbonamento Netflix Premium, controlla il numero del tuo account, la password e segui le istruzioni per utilizzare la posizione corretta nella pagina Abbonamenti. Una volta nella tua posizione, imposta un PIN per bloccare le tue informazioni e impedire ad altri di utilizzarle. Se la password cambia, accedi alla pagina di abbonamento NFXBUS per visualizzare la nuova password dell'account.",
        "notespotify":"Se hai acquistato un abbonamento Spotify Premium, controlla il numero del tuo account e la password nella pagina \"Abbonamento\"",
        "notedisney":"Se hai acquistato un abbonamento premium Disney+, controlla il numero del tuo account, la password e segui le istruzioni per utilizzare la posizione corretta nella pagina \"Abbonamento\". Una volta nella tua posizione, imposta un PIN per bloccare le tue informazioni e impedire ad altri di utilizzarle. Se la password cambia, accedi alla pagina di abbonamento NFXBUS per visualizzare la nuova password dell'account.",
        "noteyoutube":"Se hai acquistato YouTube Premium, invia la tua email di accesso a YouTube nella pagina \"Abbonamento\". Dopo l'invio, lo staff invierà un link di invito al gruppo famiglia di YouTube Premium alla tua email. Controlla il tuo indirizzo email e clicca per accettare.",
    },
    login:{
        "welcomelogin":"Benvenuti al Login",
        "login":"Login",
        "updatepwd":"Cambiare la password",
        "reg":"Account registrato",
        "agreement":", accetti i nostri Termini d'uso e confermi di aver letto la nostra Informativa sulla privacy",
        "quicklogin":"Accesso rapido",
        "sendcode":"Invia codice",
        "register":"Registro",
        "changepassword":"Cambiare la password",
        "verificationcode":"Codice di verifica",
        "name":"Nome",
        "password":"Password",
        "agapassword":"Ripeti la password",
        "modify":"Modificare",
        "validatepswlen":"La password deve contenere lettere maiuscole e minuscole, numeri e caratteri speciali, qualsiasi combinazione di tre di essi e la lunghezza deve essere compresa tra 8 e 16!",
        "checkagreement":"Si prega di controllare l'accordo",
        "passwordinconsistency":"Le password inserite due volte non sono coerenti!",
        "passwordagain":"per favore inserisci di nuovo la password",
        "verificationsend":"Il codice di verifica è stato inviato, fai attenzione a controllare",
    },
    luckdraw:{
        "lotteryticket":"biglietto della lotteria",
        "integral":"integrante",
        "welcome":"Benvenuti su nfxbus, questa è la nostra pagina delle attività, clicca sulla carta per estrarre premi con un clic, i premi includono vari pacchetti di account gratuiti, codici sconto e altri sconti, grazie per il vostro supporto e la vostra fiducia.",
        "none":"senza valore",
        "redemptioncode":"Riscatta il codice",
        "copy":"copia",
        "prizelist":"Elenco dei premi",
        "notused":"non utilizzato",
        "used":"Usato",
        "expired":"Scaduto",

        "prizeno":"Premio n.",
        "contents":"Contenuto",
        "exchangedate":"Data di cambio",
        "createtime":"creareTempo",
        "exchange":"scambio",
        "insufficientpoints":"Punti insufficienti",
        "consumepoints":"Questa operazione consumerà 10 punti. Continuare?",
        "tips":"Suggerimenti",
        "comfirm":"confermare",
        "cancel":"cancellare",
        "failtowin":"Non vincere alla lotteria",
        "congratulations":"Congratulazioni per aver ottenuto",
        "copysucceeded":"Copiato con successo!",
        "copyfailed":"Copia non riuscita!",

    },
    subscribe:{
        "renewnow":"Rinnova ora",
        "subscription":"Rinnova lo stesso abbonamento senza cambiare",
        "independent":"4K+HDR HD, registrazione di visione indipendente",
        "guarantee":"garanzia soddisfatti o rimborsati",
        "timeleft":"Tempo rimasto",
        "openingtime":"Orario di apertura del conto",
        "endtime":"Ora di fine",
        "account":"Account",
        "password":"Password",
        "profile":"Profilo",
        "pleaseuse":"per favore usa",
        "subaccount":"sottoconto",
        "myprofile":"Non occupare la posizione di qualcun altro!",
        "renew":"Rinnovare",
        "aftersales":"Post-vendita",

        "yourownsub":"1.Si prega di utilizzare il proprio subaccount in base al numero di serie",
        "delete":"2.Non modificare/eliminare altri sottoaccount",
        "modifysubscription":"3.Non modificare le informazioni relative all'abbonamento",
        "reminder":"Promemoria: se riscontri problemi, contatta tempestivamente il servizio clienti, acquista un abbonamento su nfxbus, usufruisci del servizio clienti VIP e non devi cambiare numero per il rinnovo.",

        "youtubeloginemail":"Inserisci l'email di accesso a YouTube. Devi registrarti per un gruppo home utilizzando l'indirizzo email di accesso, altrimenti verrai espulso dalla home. Se hai bisogno di assistenza, contatta il servizio clienti Nfxbus via email.",
        "inspectaddress":"Controlla il paese/regione del tuo account: KR, ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG, CZ, SE, PT, BE, Illinois. In caso di problemi, contatta i dipendenti tramite ticket entro il tempo stabilito.",
        "aftersalesservice":"servizio post-vendita",
        "support":"pagina di supporto.",
        "submit":"Invia",

        "buyit":"Non ci sono ancora abbonamenti, affrettatevi ad acquistarlo",
        "paymentsucceeded":"Pagamento riuscito",
        "month":"Seleziona un mese",
        "number":"Seleziona il numero di schermi",
        "purchase":"Seleziona il tipo di acquisto",
        "payment":"Seleziona il metodo di pagamento",
        "name":"Inserisci il nome sulla carta",
        "card":"Inserisci il numero della carta",
        "cvv":"Inserisci il CVV",
        "expirydate":"Inserisci la data di scadenza",
        "processing":"Lo stato di elaborazione è stato inviato. Controlla lo stato di elaborazione sull'ordine di lavoro.",
        "cannotempty":"Il codice sconto non può essere vuoto!",
        "remind":"Se inviare un messaggio",
        "originalpassword":"La password originale per questa operazione non verrà più utilizzata. Continuare?",
        "passwordmeets":"La password deve contenere lettere maiuscole e minuscole, numeri e caratteri speciali, qualsiasi combinazione di tre di essi e la lunghezza deve essere compresa tra 8 e 16!",
        "pwdupdatetime":"Modifica password",
        "pwdupdatetips":"Inviato, attendere pazientemente",

        "getverlink":"Ottieni il link di verifica dell'accesso",
        "ignorefeature":"Se non ti è stato chiesto di inserire il codice di verifica, ignora questa funzione.",
        "openlink":"Apri il link",
        "clicklink":"Clicca per ottenere il collegamento",
    },
    userinfo:{
        "signout":"disconnessione",
        "nickname":"Soprannome",
        "email":"E-mail",
        "code":"Codice",
        "vercode":"Codice di verifica",
        "avatar":"L'Avatar",
        "uploadavatar":"Per favore carica il tuo avatar",
        "logout":"Sei sicuro di voler effettuare il logout?",
        "onlyjpgpng":"Le immagini caricate possono essere solo in formato JPG | png",
        "cannotexceed":"La dimensione dell'immagine caricata non può superare i 5 MB!"
    },
    wish:{
        "wish":"Desiderio",
        "help":"Nfxbus sceglierà alcuni desideri per aiutare",
        "christmas":"che si avverino quando arriva Natale, lascia il tuo desiderio",
        "submit":"Invia",
        "mywish":"il mio desiderio"
    },
    map:{
        "home":"Casa",
        "userinfo":"Informazioni utente",
        "login":"Login",
        "withfriends":"Con gli amici",
        "subscribe":"Iscriviti",
        "aftersales":"Post-vendita",
        "luckdraw":"Estrazione a sorte",
        "wish":"Desiderio",
        "helpcenter":"Centro assistenza",
        "tradingstamps":"francobolli commerciali",
    },
}

export default it
