
const state = {
  menuList: [
    {
      // title:'Home',
      title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      //   title: "With friends",
      title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      //   title: "Subscribe",
      title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      //   title: "Luckdraw",
      title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
  //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      //   title: "Redeem Code",
      title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      //   title: "After Sales",
      title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      // title:'Login',
      title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListNoLogin: [
    {
      // title:'Home',
      title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      //   title: "With friends",
      title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      //   title: "Subscribe",
      title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      //   title: "Luckdraw",
      title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      //   title: "Redeem Code",
      title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      //   title: "After Sales",
      title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      // title:'Login',
      title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLogin: [
    {
      // title:'Home',
      title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      //   title: "With friends",
      title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      //   title: "Subscribe",
      title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      //   title: "Luckdraw",
      title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      //   title: "Redeem Code",
      title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      //   title: "After Sales",
      title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      // title:'User info',
      title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],



  menuListNoLoginEn: [
    {
      title:'Home',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
        title: "With friends",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
        title: "Subscribe",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
        title: "Luckdraw",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
        title: "Redeem Code",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "After Sales",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'Login',
      // title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLoginEn: [
    {
      title:'Home',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
        title: "With friends",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
        title: "Subscribe",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
        title: "Luckdraw",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
        title: "Redeem Code",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "After Sales",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'User info',
      // title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],

  menuListNoLoginAr: [
    {
      title:'بيت',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "مع الأصدقاء",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "يشترك",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "رسم محظوظ",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "استرداد الرمز",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "ما بعد البيع",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'تسجيل الدخول',
      // title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLoginAr: [
    {
      title:'بيت',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "مع الأصدقاء",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "يشترك",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "رسم محظوظ",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "استرداد الرمز",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "ما بعد البيع",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'معلومات المستخدم',
      // title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],

  menuListNoLoginEs: [
    {
      title:'Hogar',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "Con amigos",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "Suscribir",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "Sorteo",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "Canjear código",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "Postventa",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'Acceso',
      // title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLoginEs: [
    {
      title:'Hogar',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "Con amigos",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "Suscribir",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "Sorteo",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "Canjear código",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "Postventa",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'información de usuario',
      // title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],

  menuListNoLoginFr: [
    {
      title:'Maison',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "Avec des amis",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "S'abonner",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "Tirage au sort",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "Utiliser le code",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "Après-vente",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'Se connecter',
      // title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLoginFr: [
    {
      title:'Maison',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "Avec des amis",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "S'abonner",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "Tirage au sort",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "Utiliser le code",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "Après-vente",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'informations utilisateur',
      // title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],

  menuListNoLoginPt: [
    {
      title:'Lar',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "Com amigos",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "Inscrever-se",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "Sorteio",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "Resgatar código",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "Pós-venda",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'Conecte-se',
      // title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLoginPt: [
    {
      title:'Lar',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "Com amigos",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "Inscrever-se",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "Sorteio",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "Resgatar código",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "Pós-venda",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'Informações do usuário',
      // title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],

  menuListNoLoginIt: [
    {
      title:'Casa',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "Con gli amici",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "Iscriviti",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "Estrazione a sorte",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "Riscatta il codice",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "Post-vendita",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'Login',
      // title: "로그인",
      // title: this.$t("map.login"),
      path: "login",
      auth: true,
      type: "login.png",
    },
  ],
  menuListLoginIt: [
    {
      title:'Casa',
      // title: "홈페이지",
      // title: this.$t("map.home"),
      path: "home",
      auth: true,
      type: "home.png",
    },
    {
      title: "Con gli amici",
      // title: "연맹",
      // title: this.$t("map.withfriends"),
      path: "friends",
      auth: true,
      type: "friends.png",
    },
    {
      title: "Iscriviti",
      // title: "구독",
      // title: this.$t("map.subscribe"),
      path: "subscribe",
      auth: true,
      type: "subscribe.png",
    },
    {
      title: "Estrazione a sorte",
      // title: "행운추첨",
      // title: this.$t("map.luckdraw"),
      path: "luckdraw",
      auth: true,
      type: "luckdraw.png",
    },
    // {
    //     title:'Wish',
    //       title: this.$t("map.wish"),
    //     path:'wish',
    //     auth:true,
    //     type:'wish.png'
    // },
    // {
    //   title: "Help Center",
    //   title: this.$t("map.helpcenter"),
    //   path: "help",
    //   auth: true,
    //   type: "help.png",
    // },
    {
      title: "Riscatta il codice",
      // title: "상품권",
      // title: this.$t("map.tradingstamps"),
      path: "excode",
      auth: true,
      type: "excode.png",
    },
    {
      title: "Post-vendita",
      // title: "고객센터",
      // title: this.$t("map.aftersales"),
      path: "aftersales",
      auth: true,
      type: "aftersales.png",
    },
    {
      title:'Informazioni utente',
      // title: "개인적인 정보",
      // title: this.$t("map.userinfo"),
      path: "userinfo",
      auth: true,
      type: "login.png",
    },
  ],

  currentRoute:'/plat/home',
  loginstatus:false
};

const getters = {
  getMenuListData: (state) => state.menuList,
};

// mutations主要用来操作state
const mutations = {
  setMenuListData(state, data) {
    state.menuList = data;
  },
  setCurrentRoute(state, data) {
    state.currentRoute = data;
  },
  setLoginStatus(state, data) {
    state.loginstatus = data;
  },
};

export default {
  state,
  getters,
  mutations,
};
